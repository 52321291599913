import { defineStore } from "pinia";
import { categoriesApi } from "../../axios/axios"

export interface category {
  name: string,
  details: {
    id: string,
    title: string,
    description: string,
    tags?: string[],
    subCategories?: category[]
  }
}

export const useCategoryStore = defineStore('categoryStore', {
  state: () => ({
    categoriesList: {
    } as category[]
  }),
  actions: {
    async loadCategory(): Promise<void> {
      const response = await categoriesApi.getCategoriesList();
      console.log(response)
      const id = String(Math.random())

     /*this.categoriesList.push({
        id: id,
        title: notification.title ?? 'a',
        description: notification.content ?? 'a',
        tags: null,
        subCategories: null
      })*/
    }
  },
  getters: {
    getCategories(parent = null as string | null): category[] {
      return this.categoriesList
    }
  }
})