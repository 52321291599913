
import { defineComponent } from "vue";

import { IonIcon } from "@ionic/vue";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";

export default defineComponent({
  name: "fe-product-card",

  components: {
    IonIcon,
  },

  data() {
    return {
      chevronBackOutline,
      chevronForwardOutline,
    }
  },
  
  methods: {
    prev() {
      window.getSelection()?.removeAllRanges()
      let carouselCards = this.$el.querySelectorAll('.productCarousel__content > div')
      let lastitem = carouselCards[carouselCards.length - 1]
      let parentul = lastitem.parentNode
      parentul.prepend(lastitem)
      window.getSelection()?.removeAllRanges()
    },
    
    next() {
      window.getSelection()?.removeAllRanges()
      let carouselCards = this.$el.querySelectorAll('.productCarousel__content > div')
      let firstitem = carouselCards[0]
      let parentul = firstitem.parentNode
      parentul.append(firstitem)
      window.getSelection()?.removeAllRanges()
    }
  }
});
