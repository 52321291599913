import pinia from '@/store/store'
import { useUserStore } from '@/store/user'
import { useTimerStore } from '@/store/timer'
import { userApi } from "@/addons/axios";
const userStore = useUserStore(pinia)
const timerStore = useTimerStore(pinia)

export default async function statusCheck(): Promise<void> {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
        userStore.clear();
        return
    }

    if (timerStore.timer)
        clearInterval(timerStore.timer)

    const userData = await userApi.getCurrentUser()

    if (!userData || !userData.data || !userData.data.data) {
        localStorage.removeItem('refreshToken');
        return
    }

    userStore.setUser({
        userId: userData.data.data.userId ?? null,
        username: userData.data.data.username ?? null,
        name: userData.data.data.name ?? null,
        surname: userData.data.data.surname ?? null,
        email: userData.data.data.email ?? null,
        role: userData.data.data.role ?? null
    })

    timerStore.timer = setInterval(function () {
        if (!localStorage.getItem('refreshToken')) {
            clearInterval(timerStore.timer);
            userStore.clear();
        }
    }, 5000);
}