import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import { createPinia } from 'pinia'

import { IonicVue } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/common.css'

import pinia from '@/store/store'

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(pinia)

app.config.globalProperties.append = (path: string, pathToAppend: string) => {
  if (!pathToAppend || pathToAppend === '' || pathToAppend === '/')
    return '/'

  if (!path.endsWith('/'))
    path += '/'

  if (pathToAppend.startsWith('/'))
    pathToAppend = pathToAppend.substring(1)

  return path + pathToAppend
}

app.config.globalProperties.navigateToParent = (path: string) => {
  const pathParts = path.split('/')

  if (!pathParts.length)
    return '/'

  return path
}

router.isReady().then(() => {
  app.mount('#app')
})