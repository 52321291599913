/* tslint:disable */
/* eslint-disable */
/**
 * Ecommerce
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CheckPassword200Response
 */
export interface CheckPassword200Response {
    /**
     * 
     * @type {boolean}
     * @memberof CheckPassword200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {CheckPassword200ResponseData}
     * @memberof CheckPassword200Response
     */
    'data'?: CheckPassword200ResponseData;
}
/**
 * 
 * @export
 * @interface CheckPassword200ResponseData
 */
export interface CheckPassword200ResponseData {
    /**
     * 
     * @type {string}
     * @memberof CheckPassword200ResponseData
     */
    'error'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CheckPassword200ResponseData
     */
    'isOtpRequired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CheckPassword200ResponseData
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckPassword200ResponseData
     */
    'authToken'?: string;
}
/**
 * 
 * @export
 * @interface CheckPasswordRequest
 */
export interface CheckPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof CheckPasswordRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof CheckPasswordRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CheckUsername200Response
 */
export interface CheckUsername200Response {
    /**
     * 
     * @type {boolean}
     * @memberof CheckUsername200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {CheckUsername200ResponseData}
     * @memberof CheckUsername200Response
     */
    'data'?: CheckUsername200ResponseData;
}
/**
 * 
 * @export
 * @interface CheckUsername200ResponseData
 */
export interface CheckUsername200ResponseData {
    /**
     * 
     * @type {string}
     * @memberof CheckUsername200ResponseData
     */
    'error'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CheckUsername200ResponseData
     */
    'isPasswordRequired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CheckUsername200ResponseData
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface CheckUsernameRequest
 */
export interface CheckUsernameRequest {
    /**
     * 
     * @type {string}
     * @memberof CheckUsernameRequest
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface CreateCategoryRequest
 */
export interface CreateCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateCategoryRequest
     */
    'parentCategory'?: number;
}
/**
 * 
 * @export
 * @interface GetCurrentUser200Response
 */
export interface GetCurrentUser200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetCurrentUser200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetCurrentUser200ResponseData}
     * @memberof GetCurrentUser200Response
     */
    'data'?: GetCurrentUser200ResponseData;
}
/**
 * 
 * @export
 * @interface GetCurrentUser200ResponseData
 */
export interface GetCurrentUser200ResponseData {
    /**
     * 
     * @type {number}
     * @memberof GetCurrentUser200ResponseData
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUser200ResponseData
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUser200ResponseData
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUser200ResponseData
     */
    'surname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUser200ResponseData
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentUser200ResponseData
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface GetElementsDetails200Response
 */
export interface GetElementsDetails200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetElementsDetails200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof GetElementsDetails200Response
     */
    'data'?: object;
}
/**
 * 
 * @export
 * @interface Login200Response
 */
export interface Login200Response {
    /**
     * 
     * @type {boolean}
     * @memberof Login200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {Login200ResponseData}
     * @memberof Login200Response
     */
    'data'?: Login200ResponseData;
}
/**
 * 
 * @export
 * @interface Login200ResponseData
 */
export interface Login200ResponseData {
    /**
     * 
     * @type {string}
     * @memberof Login200ResponseData
     */
    'success'?: string;
    /**
     * 
     * @type {string}
     * @memberof Login200ResponseData
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface Logout200Response
 */
export interface Logout200Response {
    /**
     * 
     * @type {boolean}
     * @memberof Logout200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {Logout200ResponseData}
     * @memberof Logout200Response
     */
    'data'?: Logout200ResponseData;
}
/**
 * 
 * @export
 * @interface Logout200ResponseData
 */
export interface Logout200ResponseData {
    /**
     * 
     * @type {string}
     * @memberof Logout200ResponseData
     */
    'success'?: string;
    /**
     * 
     * @type {string}
     * @memberof Logout200ResponseData
     */
    'data'?: string;
}
/**
 * 
 * @export
 * @interface RegisterUserRequest
 */
export interface RegisterUserRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    'passwordConfirm': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    'emailConfirm': string;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterUserRequest
     */
    'isPrivacyAccepted': boolean;
}
/**
 * 
 * @export
 * @interface UserVerify200Response
 */
export interface UserVerify200Response {
    /**
     * 
     * @type {boolean}
     * @memberof UserVerify200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {UserVerify200ResponseData}
     * @memberof UserVerify200Response
     */
    'data'?: UserVerify200ResponseData;
}
/**
 * 
 * @export
 * @interface UserVerify200ResponseData
 */
export interface UserVerify200ResponseData {
    /**
     * 
     * @type {string}
     * @memberof UserVerify200ResponseData
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserVerify200ResponseData
     */
    'authToken'?: string;
}
/**
 * 
 * @export
 * @interface UserVerifyRequest
 */
export interface UserVerifyRequest {
    /**
     * 
     * @type {string}
     * @memberof UserVerifyRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserVerifyRequest
     */
    'otp': string;
}

/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {CreateCategoryRequest} createCategoryRequest Get category data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory: async (createCategoryRequest: CreateCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCategoryRequest' is not null or undefined
            assertParamExists('createCategory', 'createCategoryRequest', createCategoryRequest)
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Delete a category
         * @param {number} id Numeric ID of the category to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCategory', 'id', id)
            const localVarPath = `/categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get full list of categories
         * @summary Get categories list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoriesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {number} id Numeric ID of the category to edit
         * @param {CreateCategoryRequest} createCategoryRequest Get category data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory: async (id: number, createCategoryRequest: CreateCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCategory', 'id', id)
            // verify required parameter 'createCategoryRequest' is not null or undefined
            assertParamExists('updateCategory', 'createCategoryRequest', createCategoryRequest)
            const localVarPath = `/categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {CreateCategoryRequest} createCategoryRequest Get category data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCategory(createCategoryRequest: CreateCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetElementsDetails200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCategory(createCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This can only be done by the logged in user.
         * @summary Delete a category
         * @param {number} id Numeric ID of the category to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCategory(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetElementsDetails200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get full list of categories
         * @summary Get categories list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoriesList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetElementsDetails200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoriesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {number} id Numeric ID of the category to edit
         * @param {CreateCategoryRequest} createCategoryRequest Get category data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCategory(id: number, createCategoryRequest: CreateCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetElementsDetails200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCategory(id, createCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesApiFp(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {CreateCategoryRequest} createCategoryRequest Get category data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory(createCategoryRequest: CreateCategoryRequest, options?: any): AxiosPromise<GetElementsDetails200Response> {
            return localVarFp.createCategory(createCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This can only be done by the logged in user.
         * @summary Delete a category
         * @param {number} id Numeric ID of the category to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(id: number, options?: any): AxiosPromise<GetElementsDetails200Response> {
            return localVarFp.deleteCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get full list of categories
         * @summary Get categories list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoriesList(options?: any): AxiosPromise<GetElementsDetails200Response> {
            return localVarFp.getCategoriesList(options).then((request) => request(axios, basePath));
        },
        /**
         * This can only be done by the logged in user.
         * @summary Create a new category
         * @param {number} id Numeric ID of the category to edit
         * @param {CreateCategoryRequest} createCategoryRequest Get category data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory(id: number, createCategoryRequest: CreateCategoryRequest, options?: any): AxiosPromise<GetElementsDetails200Response> {
            return localVarFp.updateCategory(id, createCategoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
    /**
     * This can only be done by the logged in user.
     * @summary Create a new category
     * @param {CreateCategoryRequest} createCategoryRequest Get category data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public createCategory(createCategoryRequest: CreateCategoryRequest, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).createCategory(createCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This can only be done by the logged in user.
     * @summary Delete a category
     * @param {number} id Numeric ID of the category to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public deleteCategory(id: number, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).deleteCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get full list of categories
     * @summary Get categories list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public getCategoriesList(options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).getCategoriesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This can only be done by the logged in user.
     * @summary Create a new category
     * @param {number} id Numeric ID of the category to edit
     * @param {CreateCategoryRequest} createCategoryRequest Get category data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public updateCategory(id: number, createCategoryRequest: CreateCategoryRequest, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).updateCategory(id, createCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ElementApi - axios parameter creator
 * @export
 */
export const ElementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check if the provided path is a product or a category, and then gives any deatils about it.
         * @param {string} path Element\&#39;s path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElementsDetails: async (path: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('getElementsDetails', 'path', path)
            const localVarPath = `/getDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ElementApi - functional programming interface
 * @export
 */
export const ElementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ElementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check if the provided path is a product or a category, and then gives any deatils about it.
         * @param {string} path Element\&#39;s path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElementsDetails(path: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetElementsDetails200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElementsDetails(path, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ElementApi - factory interface
 * @export
 */
export const ElementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ElementApiFp(configuration)
    return {
        /**
         * 
         * @summary Check if the provided path is a product or a category, and then gives any deatils about it.
         * @param {string} path Element\&#39;s path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElementsDetails(path: string, options?: any): AxiosPromise<GetElementsDetails200Response> {
            return localVarFp.getElementsDetails(path, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ElementApi - object-oriented interface
 * @export
 * @class ElementApi
 * @extends {BaseAPI}
 */
export class ElementApi extends BaseAPI {
    /**
     * 
     * @summary Check if the provided path is a product or a category, and then gives any deatils about it.
     * @param {string} path Element\&#39;s path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementApi
     */
    public getElementsDetails(path: string, options?: AxiosRequestConfig) {
        return ElementApiFp(this.configuration).getElementsDetails(path, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check password
         * @param {CheckPasswordRequest} checkPasswordRequest Check password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPassword: async (checkPasswordRequest: CheckPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkPasswordRequest' is not null or undefined
            assertParamExists('checkPassword', 'checkPasswordRequest', checkPasswordRequest)
            const localVarPath = `/user/checkPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check the username
         * @param {CheckUsernameRequest} checkUsernameRequest Search for the username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUsername: async (checkUsernameRequest: CheckUsernameRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkUsernameRequest' is not null or undefined
            assertParamExists('checkUsername', 'checkUsernameRequest', checkUsernameRequest)
            const localVarPath = `/user/checkUsername`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkUsernameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Create user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/getCurrentUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Deletes current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/getCurrentUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/getCurrentUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user\'s configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserConfigs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/configs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by ID
         * @param {number} userId The username that needs to be fetched, admins only.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserById', 'userId', userId)
            const localVarPath = `/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary SimpleLogin
         * @param {LoginRequest} loginRequest Login data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('login', 'loginRequest', loginRequest)
            const localVarPath = `/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register user
         * @param {RegisterUserRequest} registerUserRequest User\&#39;s data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser: async (registerUserRequest: RegisterUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerUserRequest' is not null or undefined
            assertParamExists('registerUser', 'registerUserRequest', registerUserRequest)
            const localVarPath = `/user/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Update current user\'s configs
         * @param {number} body Optional description in *Markdown*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUserConfigs: async (body: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCurrentUserConfigs', 'body', body)
            const localVarPath = `/user/configs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Update user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/getCurrentUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify OTP code
         * @param {UserVerifyRequest} userVerifyRequest Optional description in *Markdown*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerify: async (userVerifyRequest: UserVerifyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userVerifyRequest' is not null or undefined
            assertParamExists('userVerify', 'userVerifyRequest', userVerifyRequest)
            const localVarPath = `/user/verifyOTPCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check password
         * @param {CheckPasswordRequest} checkPasswordRequest Check password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPassword(checkPasswordRequest: CheckPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckPassword200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPassword(checkPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check the username
         * @param {CheckUsernameRequest} checkUsernameRequest Search for the username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkUsername(checkUsernameRequest: CheckUsernameRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckUsername200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkUsername(checkUsernameRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This can only be done by the logged in user.
         * @summary Create user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This can only be done by the logged in user.
         * @summary Deletes current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCurrentUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current user\'s configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUserConfigs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserConfigs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user by ID
         * @param {number} userId The username that needs to be fetched, admins only.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary SimpleLogin
         * @param {LoginRequest} loginRequest Login data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Login200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logout current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Logout200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register user
         * @param {RegisterUserRequest} registerUserRequest User\&#39;s data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUser(registerUserRequest: RegisterUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUser(registerUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This can only be done by the logged in user.
         * @summary Update current user\'s configs
         * @param {number} body Optional description in *Markdown*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCurrentUserConfigs(body: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCurrentUserConfigs(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This can only be done by the logged in user.
         * @summary Update user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify OTP code
         * @param {UserVerifyRequest} userVerifyRequest Optional description in *Markdown*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userVerify(userVerifyRequest: UserVerifyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserVerify200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userVerify(userVerifyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Check password
         * @param {CheckPasswordRequest} checkPasswordRequest Check password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPassword(checkPasswordRequest: CheckPasswordRequest, options?: any): AxiosPromise<CheckPassword200Response> {
            return localVarFp.checkPassword(checkPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check the username
         * @param {CheckUsernameRequest} checkUsernameRequest Search for the username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUsername(checkUsernameRequest: CheckUsernameRequest, options?: any): AxiosPromise<CheckUsername200Response> {
            return localVarFp.checkUsername(checkUsernameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This can only be done by the logged in user.
         * @summary Create user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(options?: any): AxiosPromise<void> {
            return localVarFp.createUser(options).then((request) => request(axios, basePath));
        },
        /**
         * This can only be done by the logged in user.
         * @summary Deletes current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<GetCurrentUser200Response> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user\'s configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserConfigs(options?: any): AxiosPromise<void> {
            return localVarFp.getCurrentUserConfigs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by ID
         * @param {number} userId The username that needs to be fetched, admins only.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary SimpleLogin
         * @param {LoginRequest} loginRequest Login data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<Login200Response> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<Logout200Response> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register user
         * @param {RegisterUserRequest} registerUserRequest User\&#39;s data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(registerUserRequest: RegisterUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.registerUser(registerUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This can only be done by the logged in user.
         * @summary Update current user\'s configs
         * @param {number} body Optional description in *Markdown*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUserConfigs(body: number, options?: any): AxiosPromise<void> {
            return localVarFp.updateCurrentUserConfigs(body, options).then((request) => request(axios, basePath));
        },
        /**
         * This can only be done by the logged in user.
         * @summary Update user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify OTP code
         * @param {UserVerifyRequest} userVerifyRequest Optional description in *Markdown*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerify(userVerifyRequest: UserVerifyRequest, options?: any): AxiosPromise<UserVerify200Response> {
            return localVarFp.userVerify(userVerifyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Check password
     * @param {CheckPasswordRequest} checkPasswordRequest Check password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public checkPassword(checkPasswordRequest: CheckPasswordRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).checkPassword(checkPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check the username
     * @param {CheckUsernameRequest} checkUsernameRequest Search for the username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public checkUsername(checkUsernameRequest: CheckUsernameRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).checkUsername(checkUsernameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This can only be done by the logged in user.
     * @summary Create user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This can only be done by the logged in user.
     * @summary Deletes current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getCurrentUser(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user\'s configs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getCurrentUserConfigs(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getCurrentUserConfigs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by ID
     * @param {number} userId The username that needs to be fetched, admins only.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserById(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary SimpleLogin
     * @param {LoginRequest} loginRequest Login data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public login(loginRequest: LoginRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public logout(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register user
     * @param {RegisterUserRequest} registerUserRequest User\&#39;s data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public registerUser(registerUserRequest: RegisterUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).registerUser(registerUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This can only be done by the logged in user.
     * @summary Update current user\'s configs
     * @param {number} body Optional description in *Markdown*
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateCurrentUserConfigs(body: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateCurrentUserConfigs(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This can only be done by the logged in user.
     * @summary Update user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify OTP code
     * @param {UserVerifyRequest} userVerifyRequest Optional description in *Markdown*
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userVerify(userVerifyRequest: UserVerifyRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userVerify(userVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


