import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d213b886"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "productCarousel" }
const _hoisted_2 = { class: "productCarousel__scroll" }
const _hoisted_3 = { class: "productCarousel__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "productCarousel__leftArrow",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.prev()))
    }, [
      _createVNode(_component_ion_icon, { icon: _ctx.chevronBackOutline }, null, 8, ["icon"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _createElementVNode("div", {
      class: "productCarousel__rightArrow",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.next()))
    }, [
      _createVNode(_component_ion_icon, { icon: _ctx.chevronForwardOutline }, null, 8, ["icon"])
    ])
  ]))
}