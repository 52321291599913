
import { defineComponent } from "vue"

export default defineComponent({
  name: 'fe-product-card',

  props: {
    img: {
      default: 'https://dummyimage.com/360x620/fff/000',
      required: true,
      type: String
    }
  },

  data() {
    return {
    }
  }
});
