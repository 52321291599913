import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, { lines: "none" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.search()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, { icon: _ctx.searchOutline }, null, 8, ["icon"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_input, {
        modelValue: _ctx.searchData,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchData) = $event)),
        onKeypress: _cache[2] || (_cache[2] = ($event: any) => (_ctx.search($event)))
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }))
}