
import { defineComponent } from "vue";
import FeSearchbar from "@/components/FeSearchbar.vue";

import { IonIcon, IonBadge } from "@ionic/vue";
import { cartOutline, personOutline } from "ionicons/icons";

export default defineComponent({
  name: "fe-header",

  components: {
    IonIcon,
    FeSearchbar,
    IonBadge,
  },
  computed: {
    isHomePage(): boolean {
      if (!this.$route.path)
        return false

      return this.$route.path === "/"
    }
  },
  data() {
    return {
      currentHoverCategory: null as string | null,
      isCategoryHoverMenuOpen: false,
      cartOutline,
      personOutline,
      unusedLogo:
        "https://www.costaspizzeria.it/wp-content/uploads/2019/07/logo-placeholder.png",
      mainCategories: {
        "Categoria 1": {
          path: "/categoria-1",
          subCategory: {
            "Sottocategoria 11": {
              path: "/categoria-1/sottocategoria-11",
              subCategory: {
                "Sottocategoria 111": {
                  path: "/categoria-1/sottocategoria-11/sottocategoria-111",
                },
                "Sottocategoria 112": {
                  path: "/categoria-1/sottocategoria-11/sottocategoria-112",
                },
              },
            },
            "Sottocategoria 12": {
              path: "/categoria-1/sottocategoria-12",
              subCategory: {
                "Sottocategoria 121": {
                  path: "/categoria-1/sottocategoria-12/sottocategoria-121",
                },
                "Sottocategoria 122": {
                  path: "/categoria-1/sottocategoria-12/sottocategoria-122",
                },
              },
            },
          },
        },
        "Categoria 2": {
          path: "/categoria-2",
          subCategory: {
            "Sottocategoria 21": {
              path: "/categoria-2/sottocategoria-21",
            },
            "Sottocategoria 22": {
              path: "/categoria-2/sottocategoria-22",
            },
          },
        },
        "Categoria 3": {
          path: "/categoria-3",
          subCategory: {
            "Sottocategoria 31": {
              path: "/categoria-3/sottocategoria-31",
            },
            "Sottocategoria 32": {
              path: "/categoria-3/sottocategoria-32",
            },
          },
        },
        "Categoria 4": {
          path: "/categoria-4",
          subCategory: {
            "Sottocategoria 41": {
              path: "/categoria-4/sottocategoria-41",
            },
            "Sottocategoria 42": {
              path: "/categoria-4/sottocategoria-42",
            },
          },
        },
        "Categoria 5": {
          path: "/categoria-5",
          subCategory: {
            "Sottocategoria 51": {
              path: "/categoria-5/sottocategoria-51",
            },
            "Sottocategoria 52": {
              path: "/categoria-5/sottocategoria-52",
            },
          },
        },
        "Categoria 6": {
          path: "/categoria-5",
          subCategory: {
            "Sottocategoria 51": {
              path: "/categoria-5/sottocategoria-51",
            },
            "Sottocategoria 52": {
              path: "/categoria-5/sottocategoria-52",
            },
          },
        },
      },
    };
  },
});
