import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: HomePage
  },
  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/:path*',
    component: () => import('@/views/CategoryProductPage.vue')
  },
  {
    path: '/contact',
    component: () => import('@/views/ContactPage.vue')
  },
  {
    path: '/cart',
    component: () => import('@/views/CartPage.vue'),
    meta: {
      loginRequired: true
    },
  },
  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/register',
    component: () => import('@/views/RegisterPage.vue')
  },
  {
    path: '/account',
    component: () => import('@/views/AccountPage.vue'),
    meta: {
      loginRequired: true
    },
  },
  {
    path: '/account/categories',
    component: () => import('@/views/account/category/DefaultPage.vue'),
    meta: {
      loginRequired: true
    },
  },
  {
    path: '/account/categories/add',
    component: () => import('@/views/account/category/EditPage.vue'),
    meta: {
      loginRequired: true
    },
  },
  {
    path: '/account/categories/edit/:id',
    component: () => import('@/views/account/category/EditPage.vue'),
    meta: {
      loginRequired: true
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  if (to.meta && 'loginRequired' in to.meta) {
    if (to.meta.loginRequired && !localStorage.getItem('refreshToken'))
      return { path: '/login' }
    else if (!to.meta.loginRequired && localStorage.getItem('refreshToken'))
      return { path: '/' }
  }
})

export default router
