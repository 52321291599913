
import { defineComponent } from "vue";
import { IonItem, IonLabel, IonInput, IonIcon } from "@ionic/vue";
import { searchOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'fe-searchbar',

  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonIcon
  },

  data() {
    return {
      searchData: '',
      lastSearched: '',
      searchOutline
    }
  },

  methods: {
    search(event = null as any): void {
      if ((!event || event.key === 'Enter') && this.searchData.length >= 3) {
        if (this.searchData !== this.lastSearched) {
          this.lastSearched = this.searchData
        }
        else
          console.log('already searched')
      }
    }
  },
});
