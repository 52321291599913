
import { IonApp } from "@ionic/vue";
import { defineComponent } from "vue";
import statusCheck from "@/common/status";
import FeHeader from "@/components/Header.vue";
import FeFooter from "@/components/Footer.vue";
import { useCategoryStore } from '@/store/categories'

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    FeHeader,
    FeFooter,
  },

  data() {
    return {
      timeout: null as number | null,
      a: 0,
      isTimeoutActive: false,
    };
  },
  setup() {
    const category = useCategoryStore()

    return {
      category
    }
  },

  async mounted() {
    await statusCheck();
    await this.category.loadCategory()

  }
});
