
import { defineComponent } from "vue";
import FeProductList from "@/components/ProductList.vue";
import FeProductCarousel from "@/components/ProductCarousel.vue";
import FeProductCard from "@/components/ProductCard.vue";
//import { userApi } from "@/addons/axios"
import { cartOutline, personOutline, searchOutline } from "ionicons/icons";

export default defineComponent({
  components: {
    FeProductList,
    FeProductCarousel,
    FeProductCard,
  },

  data() {
    return {
      searchData: "",
      lastSearched: "",
      cartOutline,
      personOutline,
      searchOutline,
      lastProducts: [
        "https://dummyimage.com/360x640/fff/000",
        "https://dummyimage.com/360x641/aaa/000",
        "https://dummyimage.com/360x642/777/fff",
        "https://dummyimage.com/360x643/fff/000",
        "https://dummyimage.com/360x644/aaa/000",
        "https://dummyimage.com/360x645/777/fff",
        "https://dummyimage.com/360x644/aaa/000",
        "https://dummyimage.com/360x645/777/fff",
        "https://dummyimage.com/360x644/aaa/000",
        "https://dummyimage.com/360x645/777/fff",
      ],
    };
  },
});
