import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeHeader = _resolveComponent("FeHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FeFooter = _resolveComponent("FeFooter")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FeHeader),
        _createElementVNode("main", _hoisted_2, [
          _createVNode(_component_router_view)
        ]),
        _createVNode(_component_FeFooter)
      ])
    ]),
    _: 1
  }))
}