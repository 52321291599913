import { defineStore } from "pinia";

export interface userData {
  userId: number | null,
  email: string | null,
  username: string | null,
  name: string | null,
  surname: string | null,
  role: string | null
}

export const useUserStore = defineStore('userStore', {
  state: () => ({
    userId: null,
    email: null,
    username: null,
    name: null,
    surname: null,
    role: null
  } as userData),

  actions: {
    setUser(payload: userData): void {
      if (payload.userId)
        this.userId = payload.userId

      if (payload.username)
        this.username = payload.username

      if (payload.name)
        this.name = payload.name

      if (payload.surname)
        this.surname = payload.surname

      if (payload.email)
        this.email = payload.email

      if (payload.role)
        this.role = payload.role
    },
    clear(): void {
      this.userId = null
      this.email = null
      this.name = null
      this.surname = null
      this.role = null
    }
  },
  getters: {
    getUserId(state) {
      return state.userId;
    },
    getUserData(state) {
      return {
        userId: state.userId,
        username: state.username,
        email: state.email,
        name: state.name,
        surname: state.surname,
        role: state.role,
      };
    },
  }
});
