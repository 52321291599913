import { UserApiFactory, CategoriesApiFactory } from "../src/api"
import axios from 'axios'

const basePath = 'https://api.teramnall.it/ecommerce/v1'

export const userApi = UserApiFactory(undefined, basePath)
export const categoriesApi = CategoriesApiFactory(undefined, basePath)

axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('refreshToken');
    
      if (token) {
        if (!config.headers)
          config.headers = { Authorization: ''}

        config.headers["Authorization"] = 'Bearer ' + token
        config.headers["Content-Type"] = "text/plain"
     }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  
  axios.interceptors.response.use(
    (response) => {
      if (response.status > 400 && response.status <= 403) {
        console.log('Unauthorized')
      }
      return response
    },
    (error) => {
      return Promise.reject(error)
    }
  )