
import { defineComponent } from "vue";

export default defineComponent({
  name: "fe-product-list",

  data() {
    return {};
  },
});
