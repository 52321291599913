
import { defineComponent } from "vue";
import { IonIcon } from "@ionic/vue";
import { logoInstagram, logoFacebook, logoTwitter } from "ionicons/icons";

export default defineComponent({
  name: "fe-footer",

  components: {
    IonIcon,
  },

  data() {
    return {
      logoInstagram,
      logoFacebook,
      logoTwitter,
    };
  },
});
